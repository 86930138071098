import React, { Component } from "react";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image"
import * as brandStyle from "./brand.module.css"

export default class MultipleItems extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dotsClass: "slick-dots",
    };
    return (
      <main>
      <Container fluid="xl" className={brandStyle.brand}>
          <Row>
              <Col>
              <Slider {...settings}>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-01.png" alt="Brand01" /></div>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-02.png" alt="Brand02" /></div>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-03.png" alt="Brand03" /></div>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-05.png" alt="Brand05" /></div>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-06.png" alt="Brand06" /></div>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-07.png" alt="Brand07" /></div>
          <div><StaticImage className={brandStyle.brandimg} src="../images/brand-08.png" alt="Brand08" /></div>
        </Slider>
              </Col>
          </Row>
      </Container>
      </main>
    );
  }
}